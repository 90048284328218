<template>
    <w-whiteboard></w-whiteboard>
</template>

<script>
    export default {
        name: "Register",
        data: () => ({
            title: "Register"
        }),
    }
</script>

<style scoped>

</style>